import React from 'react'
import Minemech from '../Assets/Graph 1.png'
import "../styles/Mining.css"

const Mining = () => {
  return (
    <div className='mining-page-container'>
      <h1 className='mining-heading'>MINING</h1>
      <div className='mining-description'>
          In light of mass adoption being one of the backbones of any crypto currency, VYNECHAIN has adopted the native cloud mining consensus and some WEB3 functionalities in its mining app. This is an approach to create the VYNECHAIN community. 
      </div>
      <h2 className='mining-headings'>MINING MODEL</h2>
      <div className='mining-description'>This includes the initial model in the first version of the VYNECHAIN app.
        <div><span>Base Mining Rate: </span>This is the hourly VYNECOIN that will be mined by the users in the app, considering the cloud mining function is turned on.</div>
      </div>
      <h2 className='mining-heading'>Proof of Work (P.O.W)</h2>
      <div className='mining-description'>This is achieved when a user completes their daily mission on the missions page. These missions can be somewhat tiring for some users, but this is a financial calculation for the future growth of the VYNECOIN. 
        <div><span>*PS: </span>Completion of daily missions increases the user’s mining speed by 40% for that day.</div>
        <div>Rewards 80 vyneseeds( VSD).</div>
        <div>Rewards 80 vyneseeds( VSD).</div>Rewards pow x1
      </div>
      <img src={Minemech} alt='mininggraph'/>
    </div>
  )
}

export default Mining
import React from 'react'
import "../styles/Home.css"
import aboutimage from '../Assets/Youtube-thumb.png'
import stickleft from '../Assets/Avatar 29.png'
import stickright from '../Assets/Avatar 30.png'
import tokenomicspic from '../Assets/Tokenomics.png'
import { appfeatures } from '../components/Callables'

const Home = () => {
    return (
        <div className='home-container'>
            <div className='home-inner-container'>
                <div className='home-left-container'>
                    <div className='title'>
                        VYNECHAIN
                    </div>
                    <div className='description'>
                        VYNECHAIN is a blockchain that integrates the world of gaming and decentralized finance (Gamefi + DEFI) by combining the interactive engagement of gaming with the financial opportunities offered by DEFI protocols.
                    </div>
                    <a className='learn-more' href='/about'>Learn More</a>
                </div>
                <div className='home-right-container'>
                    <img className='about-img' src={aboutimage} alt="aboutimg" />
                </div>
            </div>
            <div className='betatesting-container'>
                <div className='vyneapp-title'>BETA TESTING</div>
                <div className='description'>Our Beta-testing is live!!!. Registration starts 3 days before the testing program commences and ends 2 days after the program begins. Click below to read more about the beta-testing and its perks.</div>
                <a className='learn-more' href='/beta-testing'>Read More...</a>
            </div>
            <div className='vyneapp-container'>
                <div className='vyneapp-title'>VYNE APP</div>
                <div className='vyneapp-desc'>Vyne App Features</div>
                <div className='vyneapp-card-container'>
                    {appfeatures.map((appfeature) => (
                        <div className='vyneapp-card'>
                            <div className='face face1'>
                                <div className='vyneapp-content'>
                                    <h2>{appfeature.name}</h2>
                                    <p>{appfeature.description}</p>
                                    <a className='learn' href={appfeature.page}>Continue Reading</a>
                                </div>
                            </div>
                            <div className='face face2'>
                                <img src={appfeature.image} alt='featureimage' />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='tokenomics-container'>
                <div className='tokenomics-title'>TOKENOMICS</div>
                <div className='aloc-cards'>
                    <div className='aloc-card'>TOTAL: 300M</div>
                    <div className='aloc-card'>OPENED BETA TESTING: 2% </div>
                    <div className='aloc-card'>BOUNTY CAMPAIGN: 1%</div>
                    <div className='aloc-card'>MINING: 10%</div>
                    <div className='aloc-card'>FARMING: 20%</div>
                    <div className='aloc-card'>GAMING REWARDS: 8%</div>
                    <div className='aloc-card'>TOURNAMENT REWARDS: 8%</div>
                    <div className='aloc-card'>STAKING LIQUIDITY: 2%</div>
                    <div className='aloc-card'>E-SPORTS DIVISION: 7%</div>
                    <div className='aloc-card'>PARTNERSHIPS: 3%</div>
                    <div className='aloc-card'>ADVISORS: 2%</div>
                    <div className='aloc-card'>AIRDROP: 3%</div>
                    <div className='aloc-card'>FUTURE DEVELOPMENT: 5%</div>
                    <div className='aloc-card'>TEAM: 5%</div>
                    <div className='aloc-card'>METAVERSE ECOSYSTEM: 15%</div>
                    <div className='aloc-card'>HODL: 5%</div>
                    <div className='aloc-card'>NFT RESERVE ALLOCATION: 5%</div>
                </div>
                <div className='tokenomics-desc-container'>
                    <span>OPENED BETA TESTING (2%)</span>
                    <div className='tokenomics-desc'>
                    6,000,000 VYN will be shared amongst open beta testers who meet the requirements of this testing stage. Any remainder from the open beta testing fund will be pushed into the mining pool.</div>

                    <span>BOUNTY CAMPAIGN (1%)</span>
                    <div className='tokenomics-desc'>
                    3,000,000 VYN is to be used for marketing and bounty campaign for the adoption of our ecosystem and for the benefit of the community.</div>

                    <span>MINING (10%)</span>
                    <div className='tokenomics-desc'>
                    30,000,000 VYN (in addition to potential spillover from closed and open beta testing funds) will be mined by running nodes in the VYNE mobile app. It should be of note that the node speed of mining will be reduced by 30% at every 10% of coin mined (as depicted above).</div>

                    <span>FARMING (20%)</span>
                    <div className='tokenomics-desc'>
                    60,000,000 VYN is to be allocated to the farming pool. It should be of note that at every 10% of mined funds in this pool, the seed requirement for planting in the next 10% will be increased by 30% and the daily farming yield will be decreased by 20% at the stated regular intervals.</div>

                    <span>GAMING POOL (8%)</span>
                    <div className='tokenomics-desc'>
                    24,000,000 VYN will be equally distributed in the VYNE BLOCKCHAIN road to Web3 gaming plans on Web2 devices. These coins will be awarded according to 'play to earn' algorithm. It should be of note that rewards will be halved at every 20% of coin collected in each game.</div>

                    <span>TOURNAMENT POOL (8%)</span>
                    <div className='tokenomics-desc'>
                    24,000,000 VYN will be reserved for rewards in the upcoming numerous competitions and tournaments that will be sponsored by VYNECHAIN.</div>

                    <span>STAKING LIQUIDITY (2%)</span>
                    <div className='tokenomics-desc'>
                    6,000,000 VYN will be allocated to the staking pool for wagering in the METAVERSE and Web2 games and tournaments.</div>

                    <span>E-SPORTS DIVISION (Development and Liquidity: 7%)</span>
                    <div className='tokenomics-desc'>
                    21,000,000 VYN is to be allocated for the development of the E-sports division for Web2 and Web3. It is encouraged to watch out for announcements and details on the Official VYNECHAIN channels.</div>

                    <span>PARTNERSHIPS (3%)</span>
                    <div className='tokenomics-desc'>
                    9,000,000 VYN will be reserved for future partnerships and rewards within the partners' ecosystem.</div>

                    <span>ADVISORS (2%)</span>
                    <div className='tokenomics-desc'>
                    6,000,000 VYN will be reserved for advisors and consultants that will team up with VYNECHAIN for further development over time.</div>

                    <span>AIRDROP (3%)</span>
                    <div className='tokenomics-desc'>
                    9,000,000 VYN will be airdropped to the VYNECHAIN community. The algorithm that will be used in sharing this fund will be communicated via the Official VYNECHAIN channels.</div>

                    <span>FUTURE DEVELOPMENT (5%)</span>
                    <div className='tokenomics-desc'>
                    15,000,000 VYN will be reserved as capital for the future development of the VYNE BLOCKCHAIN. This pool will be locked for 5 years.</div>

                    <span>TEAM (5%)</span>
                    <div className='tokenomics-desc'>
                    15,000,000 VYN is to be reserved for the VYNECHAIN TEAM. This pool will be locked for 5 years.</div>

                    <span>HODL (5%)</span>
                    <div className='tokenomics-desc'>
                    15,000,000 VYN will be used to rewards users who participate in various HODL to earn operations that will be introduced over time. More information will be communicated over the Official VYNECHAIN channels.</div>

                    <span>NFT RESERVE ALLOCATION (5%)</span>
                    <div className='tokenomics-desc'>
                    15,000,000 VYN will be scarcely distributed to every NFT that will ever exist in the VYNE ecosystem. These NFTs will be backed by VYNE and the reserve allocations will be dependent on the rarity of each NFT.</div>

                    <span>METAVERSE ECOSYSTEM (15%)</span>
                    <div className='tokenomics-desc'>
                    45,000,000 VYN is to be reserved for the VYNE ecosystem. This will be in accordance with tournaments, challenges etcetera, within the ecosystem.</div>
                </div>
                <img className='tokenaloc-img' src={tokenomicspic} alt="tokenimg" />
            </div>
            <div className='tokenomics-title'>ROADMAP</div>
            <div className='roadmap'>
                <div className='roadmap-container left-container'>
                <img src={stickright} alt='stick'/>
                    <div className='text-box'>
                        <h2 className='Q1Q324-text'> GENESIS </h2>
                        <small> <span className='period'> [ Q1 - Q3 ] </span> 2024 </small>
                        <div className='roadmap-text-container'>
                            <p>* Conception of VYNECHAIN</p>
                            <p>* Guerilla research</p>
                            <p>* Monetisation strategy research</p>
                            <p>* Team Building </p>
                            <p>* Branding</p>
                            <p>* Website development</p>
                            <p>* App development</p>
                            <p>* Battleground development</p>
                            <p>* Investors sourcing</p>
                        </div>
                        <span className='left-container-arrow'> </span>
                    </div>
                </div>
                <div className='roadmap-container right-container'>
                <img src={stickleft} alt='stick'/>
                    <div className='text-box'>
                        <h2 className='Q324-text'> ROOT </h2>
                        <small> <span className='period'> [ Q3 ] </span> 2024 </small>
                        <div className='roadmap-text-container'>
                            <p>* Website launch</p>
                            <p>* Tokenomics release</p>
                            <p>* Roadmap development</p>
                            <p>* Battleground infusion with app </p>
                            <p>* Developer documentation</p>
                            <p>* Guerilla marketing</p>
                            <p>* Closed testing</p>
                            <p>* App launch V1</p>
                            <p>* Community development</p>
                            <p>* Influencer selection</p>
                        </div>
                        <span className='right-container-arrow'> </span>
                    </div>
                </div>
                <div className='roadmap-container left-container'>
                <img src={stickright} alt='stick'/>
                    <div className='text-box'>
                        <h2 className='Q424-text'> STEM </h2>
                        <small> <span className='period'> [ Q4 ] </span> 2024 </small>
                        <div className='roadmap-text-container'>
                            <p>* Open beta testing</p>
                            <p>* Feedback implementation</p>
                            <p>* Main launch</p>
                            <p>* Tournament rewards implementation </p>
                            <p>* In-app transaction release</p>
                        </div>
                        <span className='left-container-arrow'> </span>
                    </div>
                </div>
                <div className='roadmap-container right-container'>
                <img src={stickleft} alt='stick'/>
                    <div className='text-box'>
                        <h2 className='Q125-text'> BRANCH </h2>
                        <small> <span className='period'> [ Q1 ] </span> 2025 </small>
                        <div className='roadmap-text-container'>
                            <p>* Ambassador election</p>
                            <p>* iOS version release</p>
                            <p>* Staking features</p>
                            <p>* P2P release</p>
                            <p>* Use case for avatar NFT release</p>
                            <p>* Explorer development</p>
                            <p>* Real world utilities</p>
                        </div>
                        <span className='right-container-arrow'> </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
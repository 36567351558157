import React from 'react'
import "../styles/Footer.css"

const Footer = () => {
  return (
    <div className='footer-container'>
        <div className='footer section-padding'>
            <div className='footer-links'>
                <div className='footer-links-div'>
                    <h4>Community</h4>
                    <a href=''>
                        <p>Telegram</p>
                    </a>
                    <a href=''>
                        <p>Discord</p>
                    </a>
                </div>
                <div className='footer-links-div'>
                    <h4>Resources</h4>
                    <a href='/mining'>
                        <p>Mining</p>
                    </a>
                    <a href='/farming'>
                        <p>Farming</p>
                    </a>
                    <a href='/battleground'>
                        <p>BattleGround</p>
                    </a>
                    <a href='/wallet'>
                        <p>Wallet</p>
                    </a>
                    <a href='/leaderboard'>
                        <p>LeaderBoard</p>
                    </a>
                </div>
                <div className='footer-links-div'>
                    <h4>Company</h4>
                    <a href='/about'>
                        <p>About</p>
                    </a>
                    <a href='/faq'>
                        <p>Frequently Asked Questions</p>
                    </a>
                    <a href='/whitepaper'>
                        <p>Whitepaper</p>
                    </a>
                    <a href='/contact'>
                        <p>Contact Us</p>
                    </a>
                </div>
                <div className='footer-links-div'>
                    <h4>Media</h4>
                    <div className='socialmedia'>

                    </div>
                </div>
            </div>
        <hr></hr>
        <div className='footer-below'>
            <div className='footer-copyright'>
                <p>
                    @{new Date().getFullYear()} Vynechain. All rights reserved
                </p>
            </div>
            <div className='footer-below-links'>
                <a href='/terms'><div><p>Terms & Conditions</p></div></a>
                <a href='/privacy'><div><p>Privacy</p></div></a>
                <a href='/security'><div><p>Security</p></div></a>
                <a href='/cookie'><div><p>Cookie Declaration</p></div></a>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Footer
import { useState } from "react"
import api from "../components/api"
import { useNavigate } from "react-router-dom"
import "../styles/Form.css"

const swal = require('sweetalert2')


function Register() {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [country, setCountry] = useState("")
    const [phone_number, setPhoneNumber] = useState("")
    const [date_of_birth, setBirthDate] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [reffered_by, setReffered_by] = useState("")
    const [errors, setErrors] = useState([])
//    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
//        setLoading(true);
        e.preventDefault();

        try {
            const response = await api.post("/api/register/", {email, username, password, password2, reffered_by})
            if (response) {
                console.log(response.data)
                swal.fire({
                    title: "Registration Success",
                    text: `Check ${response.data.email} for account activation link`,
                    icon: "success",
                    toast:  true,
                    timer: 4000,
                    position: 'center',
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
                navigate("/login")
            }
            else {
                navigate("/register")
            }

            return response
        }catch(errors) {
            setErrors(errors.response.data);
            console.log(errors.response.data);
        }finally {
//            setLoading(false)
        }
    };

    return (
        <div className="form-page-container">
            <form onSubmit={handleSubmit} 
            className="form-container">
                <h1>Register</h1>
                <input
                    className="form-input"
                    type="name"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                />
                {errors && <p style={{color: 'red'}}>{errors.username}</p>}
                <input
                    className="form-input"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                {errors && <p style={{color: 'red'}}>{errors.email}</p>}
                <input
                    className="form-input"
                    type="name"
                    required
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                />
                <input
                    className="form-input"
                    type="name"
                    required
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                />
                <select
                    className="form-input"
                    value={country}
                    required
                    defaultValue={'Select Country'}
                    onChange={(e) => setCountry(e.target.value)}
                >
                    <option>Select Country</option>
                    <option>Nigeria</option>
                    <option>United States</option>
                </select>
                <input
                    className="form-input"
                    type="phone number"
                    required
                    value={phone_number}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone Number"
                />
                <input
                    className="form-input"
                    type="date"
                    value={date_of_birth}
                    onChange={(e) => setBirthDate(e.target.value)}
                />
                <input
                    className="form-input"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <input
                    className="form-input"
                    type="password"
                    required
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    placeholder="confirm password"
                />
                {errors && <p style={{color: 'red'}}>{errors.password}</p>}

                <input
                    className="form-input"
                    type="refferal"
                    value={reffered_by}
                    onChange={(e) => setReffered_by(e.target.value)}
                    placeholder="Refferal Code"
                />
                <p>
                Already have an account? <a className="hyper" href="/login">Login</a>
                </p>
                <button className="form-button" type="submit">
                    Submit
                </button>
            </form>        
        </div>
    )
}

export default Register;
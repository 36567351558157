import { useState } from "react"
import api from "../components/api"
import { useNavigate } from "react-router-dom"
import {ACCESS_TOKEN, REFRESH_TOKEN } from "../constants"
import "../styles/Form.css"
const swal = require('sweetalert2')

function Login() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        try {
            const response = await api.post("/api/token/", {username, password})
            if (response) {
                localStorage.setItem(ACCESS_TOKEN, response.data.access);
                localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
                swal.fire({
                    title: "Login Success",
                    icon: "success",
                    toast:  true,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: true,
                })
                navigate("/dashboard")
            }else {
                navigate("/login")
            }


        }catch(errors) {
            setErrors(errors.response.data);
            console.log(errors.response.data);
        }finally {
            setLoading(false)
        }
    };

    return (
        <div className="form-page-container">
            <form onSubmit={handleSubmit} className="form-container">
                <h1>Login</h1>
                <input
                    className="form-input"
                    type="username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                />
                <input
                    className="form-input"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                {errors && <p style={{color: 'red'}}>{errors.detail}</p>}
                <p>
                Don't have an account? <a className="hyper" href="/register">Register Here</a>
                </p>
        
                <button className="form-button" type="submit">
                    Submit
                </button>
            </form>
        </div>
)
}

export default Login
import vynecoin from '../Assets/Profile-1.png'
import seedcoin from '../Assets/Profile-2.png'
import farmingpic from '../Assets/Vyne-Landscape.png'
import BG from '../Assets/BG-.png'

export const assets = [
    {
        image: vynecoin,
        name: "VYNE",
        symbol: "VYN",
        features: [
            {
                title: "minable",
                // image: ,
            },
            {
                title: "spendable",
                // image: ,
            },
            {
                title: "redeemable",
                // image: ,
            },
        ],
        description: "The Governor Coin of The Vynechain Network.",
    },
    {
        image: seedcoin,
        name: "VYNSEED",
        symbol: "VSD",
        features: [
            {
                title: "plantable",
                // image: ,
            },
            {
                title: "spendable",
                // image: ,
            },
            {
                title: "rewards",
                // image: ,
            },
        ],
        description: "The Anchor Token of The Vynechain Network.",
    },
]
        
    

export const appfeatures = [
    {
        image: '',
        name: 'Mining',
        description: 'VYNECHAIN has adopted the native cloud mining consensus and...',
        page: '/mining'
    },
    {
        image: farmingpic,
        name: 'Farming',
        description: 'VYNECHAIN farming mechanism is designed to yield the most profit...',
        page: '/farming',
    },
    {
        image: BG,
        name: 'BattleGND',
        description: "VYNECHAIN's arena games awards the user with VYNECOIN (VYN)...",
        page: '/battleground',
    },
    {
        image: '',
        name: 'Wallet',
        description: '',
        page: '/wallet',
    },
    {
        image: '',
        name: 'LDBs',
        description: 'The VYNECHAIN leaderboards track users on different activities performed...',
        page: '/leaderboard'
    }
    
]
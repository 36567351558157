import React from "react"
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import Navbar from "./components/Navbar"
import Login from "./pages/Login"
import Register from "./pages/Register"
import Home from "./pages/Home"
import NotFound from "./pages/NotFound"
import Dashboard from "./pages/Dashboard"
import ProtectedRoute  from "./components/ProtectedRoute"
import Marketplace from "./pages/Marketplace"
import Woods from "./pages/Woods"
import Steel from "./pages/Steel"
import Ropes from "./pages/Ropes"
import Cotton from "./pages/Cotton"
import About from "./pages/About"
import Farming from "./pages/Farming"
import Mining from "./pages/Mining"
import Battlegnd from "./pages/Battlegnd"
import "./App.css"
import WoodOrder from "./pages/WoodOrder"
import Leaderboard from "./pages/Leaderboard"
import Betatesting from "./pages/Betatesting"
import Footer from "./components/Footer"


function Logout() {
  localStorage.clear()
  return <Navigate to="/login" />
}

function RegisterAndLogout() {
  localStorage.clear()
  return <Register />
}

function App() {
  return (
    <div className="container">
      <div>
        <div>
          <BrowserRouter>
          <Navbar/>
            <Routes>
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/about" element={<About />} />
              <Route path="/farming" element={<Farming />} />
              <Route path="/mining" element={<Mining />} />
              <Route path="/battleground" element={<Battlegnd />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/beta-testing" element={<Betatesting />} />
              <Route path="/register" element={<RegisterAndLogout />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/marketplace/woods" element={<Woods />} />
              <Route path="/marketplace/woods/:woodId" element={<WoodOrder />} />
              <Route path="/marketplace/steel" element={<Steel />} />
              <Route path="/marketplace/ropes" element={<Ropes />} />
              <Route path="/marketplace/cotton" element={<Cotton />} />
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          <Footer/>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App

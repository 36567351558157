import React from "react"
import {IoClose, IoMenu} from "react-icons/io5"
import vchaincustom from '../Assets/Vyne@2x.png'
import "../styles/Navbar.css"

function Navbar() {
    return (
        <div>
            <div className="navbar">
                <img className="icon" src={vchaincustom} alt="customlogo"/>
                <input type="checkbox" id="sidebar-active"/>
                <label htmlFor="sidebar-active" className="open-sidebar-btn">
                    <IoMenu/>
                </label>
                <label id="overlay" htmlFor="sidebar-active"></label>
                <div className="links-container">
                    <label htmlFor="sidebar-active" className="close-sidebar-btn">
                        <IoClose/>
                    </label>

                    <a className="active" href="/">Home</a>
                    <a href="/whitepaper">WhitePaper</a>
                    <a href="/faq">FAQ</a>
                    <a href="/about">About Us</a>
                    <a href="/contact">Contact Us</a>   
                    <a className="action" href="/register">Get Started</a> 
                </div>
            </div>
        </div>
    )
}

export default Navbar